<script setup lang="ts">
const { t } = useLocale()
const { meta } = useRoute()
const { coords, resume } = useGeolocation()

const userLocation = useUserLocation()

const { refreshMenuData } = useLoadMenuData()
const { executeFilterData } = useLoadFilterData()
const { executeMetingData } = useLoadMeetingData()
const { executeUserData } = useLoadUserData()

const { refreshKatechCoords } = userLocation
const { latitude, longitude } = storeToRefs(userLocation)

const seoTitle = 'WNM'
const seoDescription = '나의 이륜차 경험을 공유하고 소통하는 공간입니다.'
const seoUrl = 'https://www.wnm.zone'
const seoImage = 'https://www.wnm.zone/image/image_og.jpg'

useHead({
  title: (meta.title as string) ?? t('pageTitle.wnm'),
  titleTemplate: (title: string | undefined) => {
    let returnTitle = null
    returnTitle = title && !title?.includes(t('pageTitle.wnm').concat(' | '))
      ? t('pageTitle.wnm').concat(' | ', title)
      : null
    return returnTitle
  },
  meta: () => [
    { name: 'msapplication-TileColor', content: '#2FC38D' },
    { name: 'theme-color', content: '#2FC38D' },
    { property: 'Imagetoolbar', content: 'no' },
    // { name: 'naver-site-verification', content: '158291e2c0440b1ab952cba20d55d36e61766b00' },
  ],
  link: [
    { rel: 'canonical', href: seoUrl },
    { rel: 'icon', type: 'image/png', href: '/image/favicon-16x16.png' },
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/image/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/image/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/image/favicon-16x16.png' },
    { rel: 'manifest', href: '/manifest.webmanifest' },
    { rel: 'mask-icon', href: '/image/safari-pinned-tab.svg', color: '#2FC38D' },
  ],
})

useSeoMeta({
  charset: 'utf-16',
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0',
  formatDetection: 'telephone=no',
  themeColor: '#2FC38D',
  keywords: 'wheelsmeets,모터사이클,오토바이,할리데이비슨,바이크,motorcycle,harleydavidson,harley,honda,혼다,ducati,두카티,bmw,비엠더블유,ktm,케이티엠,yamaha,야마하,royalenfield,로얄엔필드,custom,커스텀,커스텀바이크,custombike,custombikes,커스텀바이크',
  title: seoTitle,
  author: 'goodNodes',
  description: seoDescription,
  ogType: 'website',
  ogTitle: seoTitle,
  ogDescription: seoDescription,
  ogUrl: seoUrl,
  ogImage: seoImage,
  ogImageSecureUrl: seoImage,
  ogImageType: 'image/png',
  ogImageWidth: '410',
  ogImageHeight: '200',
  twitterCard: 'summary_large_image',
  twitterTitle: seoTitle,
  twitterDescription: seoDescription,
  twitterImage: seoImage,
})

watchEffect(() => {
  if (coords.value.latitude === Infinity) {
    resume()
    return
  }

  if (latitude.value === coords.value.latitude && longitude.value === coords.value.longitude) {
    return
  }

  latitude.value = coords.value.latitude
  longitude.value = coords.value.longitude
  refreshKatechCoords()
})

await refreshMenuData()
await executeUserData()

executeFilterData()
executeMetingData()
</script>

<template>
  <div>
    <NuxtLayout>
      <div>
        <NuxtLoadingIndicator
          color="repeating-linear-gradient(to right,#bdbdbd 0%,#cb8a80 100%)"
          :height="5"
        />
        <NuxtPage />
        <WMNotifications />
      </div>
    </NuxtLayout>
  </div>
</template>
