import type { FilterDatabase } from '@/types/supabaseFilter'

export const useLoadFilterData = () => {
  const { schemaFetchData } = useFetchComposable()

  const {
    meetingTypeData,
    meetingStatusData,
    filterPointList,
    filterMotoGenreData,
    filterBusinessType,
    filterBusinessHourData,
    filterPickupItemData,
    filterRescueSkillData,
    filterCoupon,
    filterPaymentStatus,
  } = storeToRefs(useFilterDataStore())

  const { execute: executeMeetingTypeData } = useAsyncData('meetingTypeData', async () => {
    const result = await schemaFetchData('filter', 'meetingType', '*', 'index', true)
    meetingTypeData.value = result as FilterDatabase['filter']['Tables']['meetingType']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeMeetingStatusData } = useAsyncData('meetingStatusData', async () => {
    const result = await schemaFetchData('filter', 'meetingStatus', '*')
    meetingStatusData.value = result as FilterDatabase['filter']['Tables']['meetingStatus']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterPointList } = useAsyncData('filterPointList', async () => {
    const result = await schemaFetchData('filter', 'point', 'id, code, code_name')
    filterPointList.value = result as FilterDatabase['filter']['Tables']['point']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterMotoGenreData } = useAsyncData('filterMotoGenreData', async () => {
    const result = await schemaFetchData('filter', 'motoGenre', '*')
    filterMotoGenreData.value = result as FilterDatabase['filter']['Tables']['motoGenre']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterBusinessType } = useAsyncData('filterBusinessType', async () => {
    const result = await schemaFetchData('filter', 'businessType', '*')
    filterBusinessType.value = result as FilterDatabase['filter']['Tables']['businessType']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterBusinessHourData } = useAsyncData('filterBusinessHourData', async () => {
    const result = await schemaFetchData('filter', 'businessHours', '*')
    filterBusinessHourData.value = result as FilterDatabase['filter']['Tables']['businessHours']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterPickupItemData } = useAsyncData('filterPickupItemData', async () => {
    const result = await schemaFetchData('filter', 'pickupItem', '*')
    filterPickupItemData.value = result as FilterDatabase['filter']['Tables']['pickupItem']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executefilterRescueSkillData } = useAsyncData('filterRescueSkillData', async () => {
    const result = await schemaFetchData('filter', 'rescueSkill', '*')
    filterRescueSkillData.value = result as FilterDatabase['filter']['Tables']['rescueSkill']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterCoupon } = useAsyncData('filterCoupon', async () => {
    const result = await schemaFetchData('filter', 'viewFilterCoupon', '*')
    filterCoupon.value = result as (FilterDatabase['filter']['Views']['viewFilterCoupon']['Row'] & { businessType: FilterDatabase['filter']['Tables']['businessType']['Row'] })[]

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeFilterPaymentStatus } = useAsyncData('filterPaymentStatus', async () => {
    const result = await schemaFetchData('filter', 'paymentStatus', '*')
    filterPaymentStatus.value = result as FilterDatabase['filter']['Tables']['paymentStatus']['Row'][]

    return result ?? []
  }, {
    immediate: true,
  })

  const executeFilterData = async () => {
    await executeMeetingTypeData()
    await executeMeetingStatusData()
    await executeFilterPointList()
    await executeFilterMotoGenreData()
    await executeFilterBusinessType()
    await executeFilterBusinessHourData()
    await executeFilterPickupItemData()
    await executefilterRescueSkillData()
    await executeFilterCoupon()
    await executeFilterPaymentStatus()
  }

  return {
    executeFilterData,
  }
}
